@media (max-width: 991px) {
  .guide .header-content .btn {
      margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .guide .box-black {
    margin-top: 60px;
  }
}