@charset "UTF-8";
/*!
 * Start Bootstrap - New Age v3.3.7 (http://startbootstrap.com/template-overviews/new-age)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
.heading-font {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px; }

.body-font {
  font-family: 'Muli', 'Helvetica', 'Arial', 'sans-serif'; }

.alt-font {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  text-transform: uppercase;
  letter-spacing: 2px; }

html,
body {
  height: 100%;
  width: 100%; }

body {
  font-family: 'Muli', 'Helvetica', 'Arial', 'sans-serif'; }

a {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  color: #529FFF; }

a:hover,
a:focus {
  color: #529FFF; }

hr {
  max-width: 100px;
  margin: 25px auto;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1); }

hr.light {
  border-color: white; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px; }

p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px; }

.navbar-default {
  background-color: white;
  border-color: rgba(34, 34, 34, 0.05);
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px; }

.navbar-default .navbar-header .navbar-brand {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
  color: #fdcc52; }

.navbar-default .navbar-header .navbar-brand:hover,
.navbar-default .navbar-header .navbar-brand:focus {
  color: #fcbd20; }

.navbar-default .navbar-header .navbar-toggle {
  font-size: 12px;
  color: #222222;
  padding: 8px 10px; }

.navbar-default .nav > li > a {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600; }

.navbar-default .nav > li > a,
.navbar-default .nav > li > a:focus {
  color: #222222; }

.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus:hover {
  color: #333333; }

.navbar-default .nav > li.active > a,
.navbar-default .nav > li.active > a:focus {
  color: #333333 !important;
  background-color: transparent; }

.navbar-default .nav > li.active > a:hover,
.navbar-default .nav > li.active > a:focus:hover {
  background-color: transparent; }

@media (min-width: 1201px) {
  .navbar-default {
    background: rgba(255, 255, 255, 0.9);
    border-color: transparent;
    height: 90px; }
  .navbar-default .navbar-header .navbar-brand {
    color: rgba(255, 255, 255, 0.8); }
  .navbar-default .navbar-header .navbar-brand:hover,
  .navbar-default .navbar-header .navbar-brand:focus {
    color: white; }
  .navbar-default .nav > li > a,
  .navbar-default .nav > li > a:focus {
    color: #437bbe;
    padding-top: 35px;
    padding-bottom: 35px; }
  .navbar-default .nav > li > a:hover,
  .navbar-default .nav > li > a:focus:hover {
    color: #333333; }
  .navbar-default.affix {
    background-color: white;
    border-color: rgba(34, 34, 34, 0.1); }
  .navbar-default.affix .navbar-header .navbar-brand {
    color: #437bbe; }
  .navbar-default.affix .navbar-header .navbar-brand:hover,
  .navbar-default.affix .navbar-header .navbar-brand:focus {
    color: #333333; }
  .navbar-default.affix .nav > li > a,
  .navbar-default.affix .nav > li > a:focus {
    color: #437bbe; }
  .navbar-default.affix .nav > li > a:hover,
  .navbar-default.affix .nav > li > a:focus:hover {
    color: #333333; } }

header {
  position: relative;
  width: 100%;
  min-height: auto;
  overflow-y: hidden;
  color: white; }

.features, #formulaire {
  background-color: #fff; }

header:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -20;
  width: 100%;
  height: 100%;
  background: url("../images/fond_1.jpg") no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

header.guide {
  position: relative;
  width: 100%;
  min-height: auto;
  overflow-y: hidden;
  background: url("../images/fond_4.jpg") no-repeat center fixed;
  -webkit-background-size: cover;
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée */
  color: white; }

header.page {
  position: relative;
  width: 100%;
  min-height: auto;
  overflow-y: hidden;
  background: url("../images/fond_page1.jpg") no-repeat center fixed;
  -webkit-background-size: cover;
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée */
  color: #242424; }

header.deux {
  position: relative;
  width: 100%;
  min-height: auto;
  overflow-y: hidden;
  background: url("../images/fond_page2.jpg") no-repeat center fixed;
  -webkit-background-size: cover;
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée */
  color: #242424; }

.filtre {
  background: rgba(0, 0, 0, 0.3); }

header .header-content {
  text-align: center;
  padding: 150px 0 50px;
  position: relative; }

header .header-content .header-content-inner {
  position: relative;
  max-width: 500px;
  margin: 0 auto; }

header .header-content .header-content-inner h1 {
  margin-top: 0;
  margin-bottom: 60px;
  font-size: 30px; }

header .header-content .header-content-inner .list-badges {
  margin-bottom: 25px; }

header .header-content .header-content-inner .list-badges img {
  height: 50px;
  margin-bottom: 25px; }

header .device-container {
  max-width: 300px;
  margin: 0 auto 100px; }

header .device-container .screen img {
  border-radius: 3px; }

@media (min-width: 768px) {
  header {
    max-height: 65%; }
  header .header-content {
    text-align: left;
    padding: 0;
    height: 65vh; }
  header.page .header-content {
    text-align: right;
    padding: 0;
    height: 350px; }
  header .header-content .header-content-inner {
    max-width: none;
    margin: 0;
    position: absolute;
    top: 60%;
    transform: translateY(-40%); }
  header .header-content .header-content-inner h1 {
    font-size: 35px; }
  header .device-container {
    max-width: none;
    max-height: calc(0vh);
    margin: 100px auto 0; }
  header .page {
    max-height: 30%; } }

@media (min-width: 992px) {
  header .header-content .header-content-inner h1 {
    font-size: 50px;
    font-weight: 700; } }

section {
  padding: 100px 0; }
  @media (max-width: 767px) {
    section {
      padding: 25px 0; } }

section#formulaire {
  padding: 100px 0 0; }

section#logos {
  padding: 50px 0 50px 0; }

section h2 {
  font-size: 50px; }
  @media (max-width: 767px) {
    section h2 {
      font-size: 30px; } }

section.download {
  padding: 150px 0;
  position: relative; }
  @media (max-width: 767px) {
    section.download {
      padding: 25px 0; } }

section.download h2 {
  margin-top: 0;
  font-size: 50px; }

section.download .badges .badge-link {
  display: block;
  margin-bottom: 25px; }

section.download .badges .badge-link:last-child {
  margin-bottom: 0; }

section.download .badges .badge-link img {
  height: 60px; }

@media (min-width: 768px) {
  section.download .badges .badge-link {
    display: inline-block;
    margin-bottom: 0; } }

@media (min-width: 768px) {
  section.download h2 {
    font-size: 70px; } }

section.features .section-heading {
  margin-bottom: 100px; }
  @media (max-width: 767px) {
    section.features .section-heading {
      margin-bottom: 25px; } }

section.features .section-heading h2 {
  margin-top: 0; }

section.features .section-heading p {
  margin-bottom: 0; }

section.features .device-container,
section.features .feature-item {
  max-width: 300px;
  margin: 0 auto; }

section.features .device-container {
  margin-bottom: 100px; }

@media (min-width: 992px) {
  section.features .device-container {
    margin-bottom: 0; } }

section.features .feature-item {
  text-align: center;
  margin-bottom: 100px; }

section.features .feature-item h3 {
  font-size: 30px; }

section.features h3 {
  color: #437bbe;
  font-weight: 400; }

section.features h5 {
  color: #437bbe;
  font-weight: 600;
  font-size: 16px; }

section.features .feature-item i {
  font-size: 80px;
  background: -webkit-linear-gradient(to left, #7b4397, #dc2430);
  background: linear-gradient(to left, #7b4397, #dc2430);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

section.grey {
  background-color: #F4F4F4; }

section.cta {
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background: url("../images/bg-cta.jpg") fixed;
  padding: 100px 0; }

section.cta2 {
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-image: url("../images/fond2.jpg");
  padding: 100px 0; }

section.cta3 {
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-image: url("../images/fond3.jpg");
  padding: 100px 0; }

section.cta .cta-content {
  position: relative;
  z-index: 1; }

section.cta .cta-content h2 {
  margin-top: 0;
  margin-bottom: 25px;
  color: white;
  max-width: 450px;
  font-size: 30px;
  font-weight: 600; }
  @media (max-width: 767px) {
    section.cta .cta-content h2 {
      max-width: none; } }

section.cta .cta-content p {
  margin-bottom: 25px;
  color: white;
  max-width: 450px;
  font-size: 18px;
  font-weight: 400; }
  @media (max-width: 767px) {
    section.cta .cta-content p {
      max-width: none; } }

@media (min-width: 768px) {
  section.cta .cta-content h2 {
    font-size: 40px; } }

section.cta .overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0; }

@media (max-width: 1024px) {
  section.cta .overlay {
    background-color: rgba(0, 0, 0, 0.5); } }

section.contact {
  text-align: center; }

section.contact h2 {
  margin-top: 0;
  margin-bottom: 25px; }

section.contact h2 i {
  color: #dd4b39; }

section.contact ul.list-social {
  margin-bottom: 0; }

section.contact ul.list-social li a {
  display: block;
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 40px;
  border-radius: 100%;
  color: white; }

section.contact ul.list-social li.social-twitter a {
  background-color: #1da1f2; }

section.contact ul.list-social li.social-twitter a:hover {
  background-color: #0d95e8; }

section.contact ul.list-social li.social-facebook a {
  background-color: #3b5998; }

section.contact ul.list-social li.social-facebook a:hover {
  background-color: #344e86; }

section.contact ul.list-social li.social-google-plus a {
  background-color: #dd4b39; }

section.contact ul.list-social li.social-google-plus a:hover {
  background-color: #d73925; }

footer {
  background-color: #437bbe;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  width: 100%; }

.footer-above {
  background-color: #437bbe;
  padding: 25px 0; }

.footer-below {
  background-color: #f06d25;
  color: #fff;
  font-size: 11px;
  padding: 10px 25px; }

footer p {
  font-size: 12px;
  margin: 0; }

footer ul {
  margin-bottom: 0; }

footer ul li a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  padding: 0 10px; }

footer ul li a:hover,
footer ul li a:focus,
footer ul li a:active,
footer ul li a.active {
  text-decoration: none; }

.list-footer li {
  border-right: 1px solid rgba(255, 255, 255, 0.8);
  display: inline-block;
  font-size: 12px;
  line-height: 13px;
  padding-right: 5px; }

.bg-primary {
  background-color: #437bbe; }

.bg-grey {
  background-color: #E5E6E0; }

.text-primary {
  color: #F06D25; }

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

.btn-outline {
  color: white;
  border-color: white;
  border: 1px solid; }

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active,
.btn-outline.active {
  color: white;
  background-color: #F06D25;
  border-color: #F06D25; }

.btn {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 300px;
  margin-bottom: 100px; }

.second {
  background-color: #F06D25;
  color: #ffffff; }

.second:hover {
  background-color: #d55712;
  color: #ffffff; }

.btn-xl {
  padding: 15px 45px;
  font-size: 18px;
  font-weight: 700; }

::-moz-selection {
  color: white;
  text-shadow: none;
  background: #222222; }

::selection {
  color: white;
  text-shadow: none;
  background: #222222; }

img::selection {
  color: white;
  background: transparent; }

img::-moz-selection {
  color: white;
  background: transparent; }

body {
  webkit-tap-highlight-color: #222222; }

/*************** blocs etapes ************/
.ico_etape {
  display: block;
  margin: auto; }

.etape {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center; }

.etape2 {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 24px;
  margin-top: 20px; }

h3.etape {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center; }

.sous_etape {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  max-width: 400px; }

.sous_etape2 {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
  max-width: 400px;
  margin: auto; }

.bloc_etape {
  border-right: solid 1px #ffffff; }
  @media (min-width: 768px) and (max-width: 991px) {
    .bloc_etape {
      border-right: none;
      margin-top: 20px;
      margin-bottom: 20px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .bloc_etape {
      border-right: none;
      margin-top: 20px;
      margin-bottom: 20px; } }

@media (max-width: 767px) {
  .bloc_etape {
    border-right: none;
    border-bottom: solid 1px #ffffff;
    margin: 50px 50px; }
  .sous_etape {
    margin-bottom: 50px; } }

.space {
  margin-bottom: 50px; }

.spaceless {
  margin-bottom: 0; }

/********** top formulaire ****************/
.box-black {
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  background: rgba(82, 159, 255, 0.7) none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 20px;
  margin-top: 250px;
  text-transform: uppercase; }

.box-black a.btn-orange {
  font-weight: normal; }

.btn-orange {
  display: block;
  font-size: 18px;
  padding: 8px;
  text-transform: none; }

.box-black .btn-orange {
  text-transform: uppercase; }

a.btn-orange, a.btn-blue, a.btn-white {
  background-color: #f06d25;
  border-radius: 4px;
  clear: both;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 24px;
  line-height: 25px;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  width: 50%;
  transition: background-color 0.2s ease-out 0s; }

.box-black h2 {
  font-size: 24px;
  text-align: center; }

.box-black p {
  font-size: 16px;
  text-align: center; }

/**********************************************/
form {
  max-width: 600px;
  text-align: center;
  margin: 20px auto; }

input, textarea {
  border: 0;
  outline: 0;
  padding: 1em;
  border-radius: 8px;
  display: block;
  width: 100%;
  margin-top: 1em;
  font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  box-shadow: 0 1px 1px black;
  resize: none; }

input, textarea:focus {
  box-shadow: 0 0px 2px rgba(51, 51, 51, 0.1) !important; }

.input-submit {
  color: #333333;
  background: #ffffff;
  cursor: pointer; }

.input-submit:hover {
  box-shadow: 0 1px 1px 1px rgba(51, 51, 51, 0.1);
  background: #F06D25;
  color: #ffffff;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s; }

/**********************************************/
/***************** Sidecontent ****************/
.side-container {
  background-color: #F4F4F4;
  padding: 10px; }

.side-content p {
  text-align: justify;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  line-height: 20px; }

.side-content a {
  margin: 0 0 10px 0;
  text-align: center;
  padding: 6px 50px; }

.side-content h4 {
  text-align: center; }

.side-content img {
  text-align: center;
  width: 100%; }

/********************************/
/************ liste *************/
ul.list {
  list-style: outside none none;
  margin: 0 40px;
  padding: 0; }

ul.list li {
  color: #242424;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px;
  padding-left: 15px;
  text-indent: -22px; }

ul.list li::before {
  color: #437bbe;
  content: "►";
  font-size: 14px;
  margin-right: 10px; }

/************************************/
/********* responsive ***************/
@media (max-width: 450px) {
  .navbar-header {
    min-height: 80px; }
  .navbar-brand img {
    max-height: 40px; }
  .second {
    max-width: 100%; }
  .btn-xl {
    font-size: 14px;
    letter-spacing: normal; }
  /*.icone img {
    width: 100%;
  }*/
  .box-black {
    margin-top: 0px; }
  .btn-responsive {
    margin-bottom: 0px; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  .box-black {
    margin-top: 120px; }
  .navbar-default .nav > li > a {
    font-size: 10px; }
  .navbar-brand {
    padding: 15px 5px; }
  .box-black h2 {
    font-size: 16px; }
  .box-black hr {
    margin: 10px auto; }
  .box-black p {
    font-size: 12px;
    letter-spacing: 1px; }
  /* .icone img {
    width: inherit;
  }*/
  .btn-responsive {
    margin-bottom: 50px; }
  header .header-content .header-content-inner h1 {
    font-size: 36px;
    margin-bottom: 30px; }
  section.cta {
    background-position: -500px 0; } }

@media only screen and (max-width: 1280px) {
  .box-black {
    margin-top: 150px; }
  /*.icone img {
    width: 40%;
  }*/
  section.cta {
    background-position: -300px 0; }
  section.cta2 {
    background-position: -300px center; } }

@media (max-width: 767px) {
  section.cta {
    background-position: -900px center; } }

@media (min-width: 768px) and (max-width: 991px) {
  section.cta {
    background-position: -540px center; } }

.inline-block {
  display: inline-block; }

@media screen and (max-width: 768px) {
  .box-black {
    margin-top: 0; } }

.box-black-margin-home {
  margin-top: 185px; }

.box-black-margin-dl {
  margin-top: 130px; }

.home-title-font {
  font-size: 60px; }

@media screen and (max-width: 1200px) {
  .box-black-margin-home, .box-black-margin-dl {
    margin-top: 200px; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .box-black-margin-home {
    margin-top: 145px; }
  .box-black-margin-dl {
    margin-top: 98px; } }

@media screen and (max-width: 767px) {
  .box-black-margin-home, .box-black-margin-dl {
    margin-top: 0;
    margin-bottom: 50px; }
  .cta-fix {
    margin-bottom: 0; } }

@media screen and (max-width: 370px) {
  .home-title-font {
    font-size: 30px; } }

@media (max-width: 767px) {
  .mobile-fix-bottom {
    margin-bottom: 0 !important; } }

@media (max-width: 767px) {
  .white-overlay {
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; } }

.overlay-content {
  position: relative;
  z-index: 1; }

@media (max-width: 767px) {
  .mobile-center {
    text-align: center; } }

.btn {
  white-space: normal; }

@media (max-width: 767px) {
  .fix-section-top {
    padding-top: 100px; } }

@media (max-width: 767px) {
  .resp-title-h2 {
    font-size: 22px !important; } }

@media (max-width: 767px) {
  .padding-mobile {
    padding-left: 15px;
    padding-right: 15px; } }

.text-80 {
  min-height: 80px; }

.title-h2 {
  font-size: 36px;
  font-family: 'Open Sans',Helvetica,Arial,sans-serif;
  margin-top: 0; }

.margin_btm {
  margin-bottom: 20px; }

.box {
  border: 1px solid #ddd;
  margin: 0 0 20px;
  overflow: hidden;
  padding: 19px; }

.barometre-title {
  margin-bottom: 40px;
  margin-top: 100px; }

.btn-home {
  text-align: center;
  padding: 1em;
  border-radius: 8px;
  color: #333;
  background: #fff;
  cursor: pointer; }
  .btn-home:hover {
    text-decoration: none;
    background: #F06D25;
    color: #ffffff; }
  .btn-home:active {
    color: #ffffff;
    text-decoration: none; }
  .btn-home:focus {
    color: #333;
    text-decoration: none; }

.navbar-default .navbar-header .navbar-toggle {
  margin: 22px 15px; }

footer ul, section.contact ul.list-social {
  padding: 0; }

@media (max-width: 769px) {
  .navbar-default .navbar-header .navbar-brand {
    margin: 20px 0;
    height: auto;
    padding: 0 15px; }
  .navbar-brand img {
    max-height: 40px; }
  .navbar-header {
    min-height: 80px; }
  .list-footer li {
    border-right: none;
    display: block;
    padding: 10px 0; } }

@media (min-width: 1201px) {
  li.dropdown:hover > .dropdown-menu {
    display: block; }
  .navbar-default .navbar-nav > li.dropdown:hover > a,
  .navbar-default .navbar-nav > li.dropdown:hover > a:hover,
  .navbar-default .navbar-nav > li.dropdown:hover > a:focus {
    background-color: #e7e7e7;
    color: #555555; } }

@media (max-width: 1200px) {
  .navbar-default .navbar-header .navbar-toggle {
    margin: 27px 15px; }
  .navbar-header {
    float: none; }
  .navbar-left, .navbar-right {
    float: none !important; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px; }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  .collapse.in {
    display: block !important; }
  .sous_etape, .sous_etape2 {
    max-width: none;
    text-align: center; } }

@media (max-width: 991px) {
  .bloc-download {
    margin-top: 20px;
    margin-bottom: 20px; } }

@media (max-width: 991px) {
  .guide .header-content .btn {
    margin-bottom: 0; } }

@media (max-width: 767px) {
  .guide .box-black {
    margin-top: 60px; } }

#cdd-interim, #retraite, #fonctionnaire, #divorcer, #banque {
  padding-bottom: 0; }

#qui-sommes a {
  color: #529FFF; }

#qui-sommes .section-heading {
  margin-bottom: 15px; }
