.navbar-default .navbar-header .navbar-toggle {
  margin: 22px 15px;
}

footer ul, section.contact ul.list-social {
  padding: 0;
}

@media (max-width: 769px) {
  // NAV
  .navbar-default .navbar-header .navbar-brand {
    margin: 20px 0;
    height: auto;
    padding: 0 15px;
  }
  .navbar-brand img {
    max-height: 40px;
  }
  .navbar-header {
    min-height: 80px;
  }
  // NAV END

  // FOOTER
  .list-footer li {
    border-right: none;
    display: block;
    padding: 10px 0;
  }
  // FOOTER END
}

@media (min-width: 1201px) {
  li.dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar-default .navbar-nav > li.dropdown:hover > a,
  .navbar-default .navbar-nav > li.dropdown:hover > a:hover,
  .navbar-default .navbar-nav > li.dropdown:hover > a:focus {
    background-color: rgb(231, 231, 231);
    color: rgb(85, 85, 85);
  }
}

@media (max-width: 1200px) {
  // NAV
  .navbar-default .navbar-header .navbar-toggle {
    margin: 27px 15px;
  }
  .navbar-header {
    float: none;
  }
  .navbar-left, .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }
  // NAV END

  // HOME
  .sous_etape, .sous_etape2 {
    max-width: none;
    text-align: center;
  }
  // HOME END
}