.title-h2 {
  font-size: 36px;
  font-family: 'Open Sans',Helvetica,Arial,sans-serif;
  margin-top: 0;
}

.margin_btm { margin-bottom: 20px }
.box {
  border: 1px solid #ddd;
  margin: 0 0 20px;
  overflow: hidden;
  padding: 19px;
}

.barometre-title {
  margin-bottom: 40px;
  margin-top: 100px;
}

.btn-home {
  text-align: center;
  padding: 1em;
  border-radius: 8px;
  color: #333;
  background: #fff;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background: #F06D25;
    color: #ffffff;
  }
  &:active {
    color: #ffffff;
    text-decoration: none;
  }
  &:focus {
    color: #333;
    text-decoration: none;
  }
}

