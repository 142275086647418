@mixin sm-devices {
  @media (min-width: #{$sm-width}) and (max-width: #{$md-width - 1px}) {
    @content;
  }
}

@mixin md-devices {
  @media (min-width: #{$md-width}) and (max-width: #{$lg-width - 1px}) {
    @content;
  }
}

@mixin lg-devices {
  @media (min-width: #{$lg-width}) {
    @content;
  }
}

@mixin xs-devices {
  @media (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin xxs-devices {
  @media (max-width: #{$xxs-width}) {
    @content;
  }
}

@mixin sup-xs-device {
  @media (min-width: #{$sm-width}) {
    @content;
  }
}

@mixin max-sm-device {
  @media (max-width: #{$md-width - 1px}) {
    @content;
  }
}

@mixin margin-mobile-bottom($margin) {
   @include xs-devices {
      padding-bottom: $margin;
   };
  @include sm-devices {
    padding-bottom: $margin;
  };
}

@mixin margin-mobile-top($margin) {
  @include xs-devices {
    padding-top: $margin;
  };
  @include sm-devices {
    padding-top: $margin;
  };
}


@mixin margin-mobile-top-bottom($margin) {
  @include xs-devices {
    padding-bottom: $margin;
    padding-top: $margin;
  };
  @include sm-devices {
    padding-bottom: $margin;
    padding-top: $margin;
  };
}